// Use this for default Icomoon

@include fontFaceV2(
 $fontName: "icomoon",
 $fileName: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);


@include fontFaceV2( $fontName: "Roboto", $fileName: "roboto-v20-latin", $weights: ("300", "400", "500", "700", "900"), $types: all);