// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
}
%buttonHover {
	// Link Hover
	color: $primary;
	
	
}
%buttonActive {
	// Link Active
	color: $primary;

}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $light; // background-color
$barColor: $dark; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					a, span{
						font-weight: 300;
					}
					@include navButtons {
						@include navButton;
					}
					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}
					@include navBack {
					}
				}
			}
		}
	}
			.naviContact{
				display: none;

				}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#navigation {

		nav {
			margin: 1rem 0 .5rem;
			ul {
				&.navi {
					.anfahrt, .impressum, .datenschutzerklaerung, {
						display: none;
					}
					@include breakpoint(giant) {
					.kontakt{
						display: none;
					 }
					}
					
					li {
						&.hasSub {
							>span:after{
								color: $primary;
								font-size: .8rem;
							}
							.sub {
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
		.naviContact{
			
			@include breakpoint(large) {
				font-weight: 300;
			 	display: flex;
    			justify-content: flex-end;
    			p{
    				padding: 0rem 0 1rem;
    				margin-right: 1.2rem;
    				a{
    					text-decoration: none;
    					&.mail{
    						&:before{
    						padding-right: .8rem	
    						}
    					}
    				}
    				span{
    					&.phone{
							&:before{
    						padding-right: .6rem	
    						}
    					}    					
    				}

    			}
			.facebook{
			    background: url(../images/layout/facebook.png) no-repeat scroll 0 0;
   				display: block;
   				width: 25px;
   				height: 25px;
   				text-align: center;
   				text-indent: -588.176rem;
   				background-size: cover;
   				transition: .5s;
				&:hover{
					transform: scale(1.2);
				}
			}

			}
			@include breakpoint(giant) {
			justify-content: space-between;
			p{
				margin: 0;
				padding: 0;
			 }
			}
			
			@include breakpoint(huge) {
			    justify-content: flex-end;
			    p{
			    	margin-left: 1.8rem;
			    }
			 }
			
		}
	}
}

// Quickbar Styles
////
#quickbar {
	box-shadow: 0px 2px 10px 0px rgba(150,150,150,0.5);
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	display: block;
	margin-top: 2rem;
	li {
		text-align: center;
		a{
			font-weight: 300;
			text-decoration: none;
			color: $dark;
			display: block;
			padding: 0.8rem 1rem;
			transition: .5s;
			&:hover{
				color: $primary;
				transition: .5s;
				
			}
			&.active{
				color: $primary;
				transition: .5s;
			}
		}
	}
	@include breakpoint(medium) {
	    display: flex;
    	justify-content: center;
    	li{
    		margin-right: 1rem;
    		margin-bottom: 2rem;

    	}
	 }
	 
	 
	
}