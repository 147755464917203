/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 16px;

$baseLineHeight: 24px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 26px;

$h4Size: 24px;

$h5Size: 22px;

$h6Size: 16px;

$mainFont: Roboto, sans-serif;

$displayFont: Roboto, sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #fe019a;
$secondary: #ededed;
$light: #fff;
$medium: #F2F2F0;
$dark: #454545;
$border: #d1d1d1;
$alert: #D85D00;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	times: "\f00d", 
	minus: "\f068", 
	angle-right: "\f054", 
	plus: "\f067", 
	angle-up: "\f077", 
	exclamation: "\f06a", 
	clock: "\e94f", 
	phone: "\f095", 
	mail: "\f1d8", 
	check: "\f00c", 
	user: "\f007", 
	facebook: "\e900", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (6),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (6, 12),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (6, 12),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (3, 4, 6, 12),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (6),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (3, 4, 8),
	),
	huge: (
		width: em(1364px, $bpContext), 
	),
	full: (
		width: em(1520px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

