
// HEADER
//////////////////////////////

#header {
	position: relative;
	padding: rem(100px) rem($baseGap);
	width: 100%;
	margin-bottom: 6rem;

	@include responsiveImage('layout/Headerbg.jpg', (tiny, medium), true);

	&.lazyLoaded {
		background-size:cover;
		    background-position: center;
	}
	@include breakpoint(small) {
	margin-bottom: 8rem;
	 }
	
	@include breakpoint(medium) {
		padding: 8rem 0 6rem;
		
	}
	@include breakpoint(large) {
	padding: 10rem 0 12rem;
	margin-bottom: 8rem;
	 }
	@include breakpoint(huge) {
	padding: 12rem 0 14rem;
	 }
	
	.branding {
		&:before{
			content: '';
			background: url(../images/layout/branding.png);
			display: block;
			max-width: 200px;
			width: 100%;
			height: 63px;
			margin: 0 auto;
			position: relative;
			z-index: 0;
			background-size: contain;
			@include breakpoint(tiny) {
			
   				 max-width: 425px;
   			   	 height: 134px;
   				 background-size: cover;
			 }
			 @include breakpoint(large) {
			 max-width:900px;
			 height: 284px;
			 background: url(../images/layout/branding_large.png);
			  }
		}
	}

	.nummer{
	@include breakpoint(large) {
			&:after{
				content: '';
				background: url(../images/layout/nummer.png);
				display: block;	
				position: absolute;
				transform: translateX(55%);
    			left: 50%;
				width: 45px;
				height: 282px;
   				 top: -17.7rem;
			 	z-index: 999;
			 	background-position:bottom;
			}		
		

&.animated {
	transition:all .1s ease 0s;


	body.noJS & {
		transition:all .1s ease 0s;
		transform:translate(0,0);
	}
	
	&.left {
		transform: translate(300px, 0);
	}
	
	&.right {
		transform: translate(0, 0);		
	}
	
	&.top {
		transform: translate(0, 0);
	}
	
	&.lazyLoaded {
		transition:all 4s ease 0s;
		transform:translateY(0);
	
	&.left, &.top {
		&:after{
			transition:all 4s ease 0s;
			height: 217px;
		}
	}
	}
}
 }
	}



	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}
	&:before{
		content: '';
		background: url(../images/index/kran.png);
		width: 270px;
		height: 270px;
		position: absolute;
		background-size: contain;
		max-width: 120px;
   		max-height: 158px;
   		bottom: -5.5rem;
   		right: 50%;
   		transform: translateX(-13%);
   		
   		 @include breakpoint(large) {
   		 right: 2rem;
   		 max-width: 190px;
		 max-height: 250px;
		 bottom: -9rem;
		 transform: translateX(-129%);
   		  }
   		   @include breakpoint(full) {
   		  right: 50%;
   		  transform: translateX(253%);
   		   }
   		
	}
	&:after{
		content: '';
		background: url(../images/index/erfahrung.png);
		width: 270px;
		height: 270px;
		position: absolute;
		background-size: contain;
		max-width: 120px;
   		max-height: 158px;
   		bottom: -5.5rem;
   		right: 50%;
   		transform: translateX(109%);
   		@include breakpoint(medium) {
   			transform: translateX(125%);
   		 }
   		 @include breakpoint(large) {
   		 	right: 18rem;
   		 	max-width: 190px;
		 	max-height: 250px;
		 	bottom: -9rem;
		 	transform: translateX(129%);
   		  }	
   		  @include breakpoint(full) {
   		  right: 50%;
   		  transform: translateX(372%);
   		   }
   		  
	}
}

// MAIN CONTENT
//////////////////////////////


main {
	display: block;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	
	p.highlightBrand {

		text-transform: uppercase;
		font-weight: bold;
		font-size: 1.2rem;

		@include breakpoint(large) {
			font-size: rem(30px);
			margin-bottom: 2rem;
			text-transform: uppercase;
			font-weight: bold;
		 }
	}



	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}
	.mapbox{
		margin-top: 3rem;
		@include breakpoint(medium) {
		margin-top: 0;
		 }
		
		ul{
			width:auto !important;
			&:last-child{
				padding-bottom: 1rem;
				border-bottom: 1px solid $border;
			}
		}
	}

	.teasercontainer{
		margin: 3rem 0;
		position: relative;
		height: auto;
		background-size: cover;
			@include breakpoint(small) {
				margin: 5rem 0;
			 }
			@include breakpoint(giant) {
				// margin: 10rem 0;
			 }

				a{
					text-decoration: none;
					.teaser1{
						background: url(../images/index/hoehentransporte.jpg) center/cover;
						margin-bottom: 2rem;
						
						.teaserInner {	
							p{
								&:before{
							content: '';
							background: url(../images/index/hoehentransporte.png);
					
							}								
							}

						}
						
					}
					.teaser2{
						background: url(../images/index/dachrinnenreinigungen.jpg) center/cover;
						margin-bottom: 2rem;
						.teaserInner {	
							p{
							&:before{
							content: '';
							background: url(../images/index/dachrinnenreinigungen.png);
					
							}								
							}

						}						
					}
					.teaser3{
						background: url(../images/index/baufaellung.jpg) center/cover;

						.teaserInner {	
							p{
							&:before{
							content: '';
							background: url(../images/index/baufaellung.png);
					
							}								
							}

						}
					}
					.teaserInner {

						position: relative;
						display: block;
						text-align: left;
						z-index: 2;
						transition: .5s;

						p {
							text-align: left;
							color: $light;
 							margin-bottom: 0;	
 							text-align: center;
 							padding:9rem 2rem 0;
 							height: 20rem;
 							@include breakpoint(medium) {
 								padding:7rem 1rem 0;
 							 }
 							@include breakpoint(large) {
 								padding: 14rem 1rem 0;
 								display: flex;
 								align-items: center;
 							 }
 							
							&:before{
								 width: 95px;
   								 margin: auto;
   								 display: block;
   								 margin-bottom: 0;
   								 padding: 2rem 0;
   								 position: absolute;
   								 height: 60px;
   								 left: 50%;
   								 transform: translateX(-50%);
   								 top: 4rem;
   								 @include breakpoint(medium) {
   								 top: 2rem;
   								  }
   								 @include breakpoint(large) {
   								 top: 3rem;
   								  }
								@include breakpoint(giant) {
								top: 3rem;
								 }
								
   								 
							} 								
 								@include breakpoint(large) {
 									height: rem(360px);
 								 }
 							
 							&.teaserbtn{
 								transition: .5s;
 								background-color: $primary;
 								// font-weight: 700;
 								text-align: center;
 								padding: 2rem 1rem;
 								font-size: rem(20px);
 								height: 3rem;
 								display: flex;
 								align-items: center;
 								justify-content:center;
 								line-height: 1.5rem;

 							}
 							@include breakpoint(giant) {
 								padding: 14rem 4rem 0;
 								 }
 							@include breakpoint(full) {
 								padding: 14rem 5rem 0;
 							 }

 							
						}
						&:hover{
							p{
								&.teaserbtn{
									transition: .5s;
									background-color: lighten($dark, 15%);
								}
							}
						}
					}
				}
	}

	.lastcontent{
		margin-top: 3rem;
		@include breakpoint(small) {
		margin-top: 5rem;
		 }
		
	}
	ul{
		li{
			.break{
				display: block;
				margin-left: 1.5rem;
				@include breakpoint(tiny) {
				display: inline-block;
				margin-left: 0;
				 }
				
			}
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}

}

// FOOTER
//////////////////////////////

#footer {
	
	margin-top: 3rem;
	padding: 0rem 0 1rem ;
	@include breakpoint(small) {
	margin-top: 5rem;
	 }
	
	@include breakpoint(medium) {
	padding: 0;
	 }
	
	.contact{
		background: $secondary;
		img{
			max-width: 550px;
			margin: 2rem auto 0;
			display: block;
			@include breakpoint(medium) {
			margin: 6.5rem auto;
			 }
			
			@include breakpoint(giant) {
			margin: 5rem auto;
			 }
			
		}
		.contactinfo{
			text-align: center;
			font-weight: 300;
			padding: 2rem 0;
			font-size: .9rem;
			.block{
			    p{
   		    	margin-bottom: .3rem;
   				    }
			}
			@include breakpoint(tiny) {
			font-size: 1rem;
			 }
			
			@include breakpoint(medium) {
			padding: 3rem 0;
			text-align: left;
			margin: auto;
			.block{
				display: flex;
   				flex-direction: column;
   				justify-content: center;
   				    line-height: 1.7rem;

   				.clock{
   					span{
						margin-left: 1.5rem;
						&.first{
							margin-left: 3.3rem;
						}
   					}
   				}
			 }
			 }
			@include breakpoint(giant) {
			padding: 0;
			display: flex;
			margin: 0;
			justify-content: space-around;

			}
			
		}
	}
}

body.index{
	main{
		span.hr{
			margin: 3rem 0;
			@include breakpoint(small) {
			margin: 5rem 0;
			 }
			
		}
		ul{
			margin-bottom: 3rem;
			li{
				margin-bottom: 1rem;
				padding-bottom: 1rem;
				border-bottom: 1px solid $border;
				&:last-child{
					border-bottom: none;
					margin-bottom: 0;
					padding-bottom: 0;
				}
			}
			@include breakpoint(tiny) {
			width: 23rem;
			margin: 0 auto 3rem;
			 }
			@include breakpoint(medium) {
			margin:0 auto;
			display: flex;
    		flex-direction: column;
    		justify-content: center;
			 }
			
		}
	}
}


///ICONS
/////////////

.clock{
	font-family: $mainFont;
	&:before{
		content: map-get($iconMap, clock);
		font-family: $iconFont;
		padding-right: .3rem;
		color: $primary;
		@include breakpoint(tiny) {
		padding-right: .5rem;
		 }
		
	}
}

.user{
	font-family: $mainFont;
	&:before{
		content: map-get($iconMap, user);
		font-family: $iconFont;
		padding-right: .3rem;
		font-size: 1.1rem;
		color: $primary;
		@include breakpoint(tiny) {
		padding-right: .6rem;
	    padding-left: .1rem;
		 }
	}
}

.mail{
	font-family: $mainFont;
	&:before{
		content: map-get($iconMap, mail);
		font-family: $iconFont;
		padding-right: .3rem;
		color: $primary;
		@include breakpoint(tiny) {
		padding-right: .5rem;
		 }
	}
}

.mobil{
	font-family: $mainFont;
	&:before{
		content: map-get($iconMap, phone);
		font-family: $iconFont;
		padding-right: .3rem;
		color: $primary;
		font-size: 1.1rem;
		@include breakpoint(tiny) {
		padding-right: .5rem;
		 }
	}
}

///animate
///////////////////
@include breakpoint(large) {
// .animated {
// // opacity:0;
// // visibility:hidden;
// transition:all .1s ease 0s;
// // transform:translateY(300px);

// body.noJS & {
// // opacity:1;
// // visibility:visible;
// transition:all .1s ease 0s;
// transform:translate(0,0);
// }

// &.left {
// transform: translate(300px, 0);
// }

// &.right {
// transform: translate(0, 0);
				
			
// }

// &.top {
// transform: translate(0, 0);
// }

// &.lazyLoaded {
// // opacity:1;
// // visibility:visible;
// transition:all 4s ease 0s;
// transform:translateY(0);

// &.left, &.top {
// transform: translate(0, -64px);
// }
// }
// }
 }




///notINDEX
///////////////////

body.cmsBackend{
	#header{
		display: none;
	}
	main{
		margin-top: 11rem;
	}
}

body.kontakt,.impressum{

	dd{
		.phone{
		&:before{
			display: none;
		}
	}
	}
	
}

body.mietkrane {
	h3{
		margin-top: 2rem;
	}
}


body:not(.index){

	#header{
		    padding: 4rem 1.125rem 1rem;
		    margin-bottom: 3rem;
		    @include breakpoint(tiny) {
		   		padding: 4rem 1.125rem 1rem;
		     }
		     @include breakpoint(small) {
		     margin-bottom: 6rem;
		      }
		     
		     @include breakpoint(large) {
		     padding: 8.5rem 1.125rem 1rem;
		      }
		      @include breakpoint(giant) {
		      padding: 6.5rem 1.125rem 1rem;
		       }
		      
		     
		    
		&:before{
			display: none;
		}
		&:after{
			display: none;
		}

	.branding {
		&:before{
			content: '';
			background: url(../images/layout/branding.png);
			display: block;
			max-width: 200px;
			width: 100%;
			height: 63px;
			margin: 0 auto;
			position: relative;
			z-index: 0;
			background-size: contain;
			@include breakpoint(tiny) {
			
   				 max-width: 425px;
   			   	 height: 134px;
   				 background-size: cover;
			 }
			 @include breakpoint(large) {
				max-width: 548px;
    			height: 176px;
			 background: url(../images/layout/footerbranding.png);
			  }
		}
	}
	.nummer{
		display: none;
	}
	}
}

body.referenzen{
	h4{
		height: 3rem;
	}

	div.gallery {
	padding-left: 0;
	padding-right: 0;

	img.col {
		margin: 0.5rem 0 1rem 0rem;
	}
}
}